import { Box, Grid, makeStyles, Typography } from '@material-ui/core'

const title1 = 'Finance'
const title2 = 'Legal'
const title3 = 'Marketing'
const title4 = 'Pharma & Medical'

const content1 = `Annotate Bloomberg chats, earning call transcripts or social media to find the signal in the noise.`
const content2 = `NLP is the driver of LegalTech and labeled data drives NLP. Use LightTag to label contracts and case law to grow you LegalTech capabilities.`
const content3 = `Search and annotate social media to find brand mentions and sentiment in any domain and language.`
const content4 = `Easily annotate drug-drug interactions or site-symptom-severity triplets in clinical notes.`

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: '60px',
  },
}))

const DatasetsVP = () => {
  const classes = useStyles()
  return (
    <Grid>
      <Box className={classes.item}>
        <Typography variant="h6">{title1}</Typography>
        <Typography variant="body2">{content1}</Typography>
      </Box>
      <Box className={classes.item}>
        <Typography variant="h6">{title2}</Typography>
        <Typography variant="body2">{content2}</Typography>
      </Box>
      <Box className={classes.item}>
        <Typography variant="h6">{title3}</Typography>
        <Typography variant="body2">{content3}</Typography>
      </Box>
      <Box className={classes.item}>
        <Typography variant="h6">{title4}</Typography>
        <Typography variant="body2">{content4}</Typography>
      </Box>
    </Grid>
  )
}

export default DatasetsVP
