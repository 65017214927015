import { Grid, Typography } from '@material-ui/core'
import { ExternalNavLink } from '../navbar/navbar'

const title = 'Work Faster With Our Optimized Interface'

const WorkFasterVP = () => {
  return (
    <Grid>
      <Typography variant="h6">{title}</Typography>
      <Typography component="div" variant="body2">
        <ul
          style={{
            paddingLeft: '20px',
          }}
        >
          <li>Keyboard Shortcuts </li>
          <li>No tokenization assumptions</li>
          <li>Full Unicode Support</li>
          <li>Subword and phrase annotations</li>
          <li>RTL and CJK languages </li>
          <li>Entity, Classification and Relation annotations</li>
        </ul>
        <ExternalNavLink
          to={
            'https://www.lighttag.io/help/en/articles/4325761-annotating-entities'
          }
        >
          Learn More
        </ExternalNavLink>
      </Typography>
    </Grid>
  )
}

export default WorkFasterVP
