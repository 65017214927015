const DataAsset = () => {
  return (
    <svg width="136" height="96" viewBox="0 0 136 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="8.5" y="5.5" width="119" height="79" rx="3.5" stroke="black" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M54 44.9498C54 52.6898 60.26 58.9498 68 58.9498C72.42 58.9498 76.34 56.9098 78.9 53.7098L84.1 56.7698C80.46 61.7298 74.6 64.9498 67.98 64.9498C56.94 64.9498 48 55.9898 48 44.9498C48 34.5698 55.88 26.0498 66 25.0498V31.1098C59.22 32.0898 54 37.8898 54 44.9498ZM70 31.1098V25.0498C80.1 26.0498 88 34.5898 88 44.9498C88 47.8498 87.36 50.6098 86.24 53.0898L81.04 50.0298C81.64 48.4498 82 46.7498 82 44.9498C82 37.8898 76.78 32.0898 70 31.1098Z" fill="black" />
      <path d="M80 31H97" stroke="black" stroke-width="4" />
      <circle cx="36" cy="60" r="4" stroke="black" stroke-width="4" />
      <circle cx="101" cy="31" r="4" stroke="black" stroke-width="4" />
      <path d="M40 59.5H57" stroke="black" stroke-width="4" />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="136" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>

  );
}

export default DataAsset; 