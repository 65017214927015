const ControlAsset = () => {
  return (
    <svg width="136" height="96" viewBox="0 0 136 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="8.5" y="5.5" width="119" height="79" rx="3.5" stroke="black" />
      </g>
      <rect x="38" y="21" width="60" height="48" fill="white" />
      <path d="M47 59.5L62.5 44L70.5 52L94.5 28" stroke="black" stroke-width="4" />
      <circle cx="47" cy="60" r="4" fill="white" stroke="black" stroke-width="4" />
      <path d="M87 27.5H94.5V35" stroke="black" stroke-width="3" />
      <circle cx="62.5" cy="33.5" r="3.5" fill="white" stroke="black" stroke-width="2" />
      <circle cx="81.5" cy="58.5" r="3.5" transform="rotate(180 81.5 58.5)" fill="white" stroke="black" stroke-width="2" />
      <rect x="61" y="37" width="3" height="7" fill="black" />
      <rect x="83" y="56" width="3" height="15" transform="rotate(180 83 56)" fill="black" />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="136" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default ControlAsset;