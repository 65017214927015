const SearchAsset = () => {
  return (
    <svg width="136" height="96" viewBox="0 0 136 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="8.5" y="5.5" width="119" height="79" rx="3.5" stroke="black" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M67.42 49.5098H69L78.98 59.5098L76 62.4898L66 52.5098V50.9298L65.46 50.3698C63.18 52.3298 60.22 53.5098 57 53.5098C49.82 53.5098 44 47.6898 44 40.5098C44 33.3298 49.82 27.5098 57 27.5098C64.18 27.5098 70 33.3298 70 40.5098C70 43.7298 68.82 46.6898 66.86 48.9698L67.42 49.5098ZM48 40.5098C48 45.4898 52.02 49.5098 57 49.5098C61.98 49.5098 66 45.4898 66 40.5098C66 35.5298 61.98 31.5098 57 31.5098C52.02 31.5098 48 35.5298 48 40.5098Z" fill="black" />
      <rect x="81.4902" y="24" width="4" height="16" fill="black" />
      <rect x="91.4902" y="30" width="4" height="16" transform="rotate(90 91.4902 30)" fill="black" />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="136" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SearchAsset;