import { Grid, Typography } from '@material-ui/core'

const title = 'Double Your Throughput With Our AI'
const content1 = `LightTag's AI quickly learns high precision predictions, automating away simple labels and freeing your team to create more and higher quality labels.`
const content2 = `50% of the annotations made in LightTag come from our AI suggestions, in any language! `

const DoubleThroughputVP = () => {
  return (
    <Grid>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{content1}</Typography>
      <Typography variant="body2">{content2}</Typography>
    </Grid>
  )
}

export default DoubleThroughputVP
