import { Grid, Typography } from '@material-ui/core'

const title = 'Model More Accurately'
const content =
  `You can also provide suggestions with your own models, ` +
  `regular expressions and dictionaries. Use our review feature to quickly ` +
  `validate your models and bootstrap a project.`

const ModelAccuracyVP = () => {
  return (
    <Grid>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{content}</Typography>
    </Grid>
  )
}

export default ModelAccuracyVP
