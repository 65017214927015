const ScaleAsset = () => {
  return (
    <svg width="136" height="96" viewBox="0 0 136 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="8.5" y="5.5" width="119" height="79" rx="3.5" stroke="black" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M68.0001 45C71.8601 45 75.0001 41.86 75.0001 38C75.0001 34.14 71.8601 31 68.0001 31C64.1401 31 61.0001 34.14 61.0001 38C61.0001 41.86 64.1401 45 68.0001 45ZM54.0001 55.5C54.0001 50.84 63.3201 48.5 68.0001 48.5C72.6801 48.5 82.0001 50.84 82.0001 55.5V59H54.0001V55.5ZM67.9999 52.5C64.4199 52.5 60.3599 53.84 58.6799 55H77.3199C75.6399 53.84 71.5799 52.5 67.9999 52.5ZM71.0001 38C71.0001 36.34 69.6601 35 68.0001 35C66.3401 35 65.0001 36.34 65.0001 38C65.0001 39.66 66.3401 41 68.0001 41C69.6601 41 71.0001 39.66 71.0001 38ZM82.0802 48.6201C84.4002 50.3001 86.0002 52.5401 86.0002 55.5001V59.0001H94.0002V55.5001C94.0002 51.4601 87.0002 49.1601 82.0802 48.6201ZM87.0001 38C87.0001 41.86 83.8601 45 80.0001 45C78.9201 45 77.9201 44.74 77.0001 44.3C78.2601 42.52 79.0001 40.34 79.0001 38C79.0001 35.66 78.2601 33.48 77.0001 31.7C77.9201 31.26 78.9201 31 80.0001 31C83.8601 31 87.0001 34.14 87.0001 38ZM53.92 48.6201C51.6 50.3001 50 52.5401 50 55.5001V59.0001H42V55.5001C42 51.4601 49 49.1601 53.92 48.6201ZM49.0001 38C49.0001 41.86 52.1401 45 56.0001 45C57.0801 45 58.0801 44.74 59.0001 44.3C57.7401 42.52 57.0001 40.34 57.0001 38C57.0001 35.66 57.7401 33.48 59.0001 31.7C58.0801 31.26 57.0801 31 56.0001 31C52.1401 31 49.0001 34.14 49.0001 38Z" fill="black" />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="136" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default ScaleAsset;