import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  asset: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
}))

const ModelAccuracyAsset = () => {
  const classes = useStyles()
  return (
    <svg
      className={classes.asset}
      height="221"
      viewBox="0 0 355 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M169.5 23L214.5 119" stroke="white" strokeWidth="2" />
      <path d="M328.4 186.158L238 130.763" stroke="white" strokeWidth="2" />
      <path d="M269.5 55.5L235 95.5" stroke="white" strokeWidth="2" />
      <path d="M203.609 144L175.039 188.43" stroke="white" strokeWidth="2" />
      <circle
        cx="214"
        cy="117"
        r="38"
        fill="#232323"
        stroke="white"
        strokeWidth="4"
      />
      <circle
        cx="169"
        cy="24"
        r="23"
        fill="#232323"
        stroke="white"
        strokeWidth="2"
      />
      <circle
        cx="331"
        cy="187"
        r="23"
        fill="#232323"
        stroke="white"
        strokeWidth="2"
      />
      <circle
        cx="171"
        cy="197"
        r="23"
        fill="#232323"
        stroke="white"
        strokeWidth="2"
      />
      <circle
        cx="278"
        cy="48"
        r="23"
        fill="#232323"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M117 98H133C134.657 98 136 99.3431 136 101V133C136 134.657 134.657 136 133 136H117C106.507 136 98 127.493 98 117C98 106.507 106.507 98 117 98Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M136 108H146C147.105 108 148 108.895 148 110V110C148 111.105 147.105 112 146 112H136V108Z"
        fill="white"
      />
      <path
        d="M136 122H146C147.105 122 148 122.895 148 124V124C148 125.105 147.105 126 146 126H136V122Z"
        fill="white"
      />
      <path
        d="M35 117C35 116.448 35.4477 116 36 116H99V118H36C35.4477 118 35 117.552 35 117V117Z"
        fill="white"
      />
      <rect
        x="2"
        y="99"
        width="36"
        height="36"
        rx="2"
        fill="#232323"
        stroke="#A571CD"
        strokeWidth="4"
      />
    </svg>
  )
}

export default ModelAccuracyAsset
