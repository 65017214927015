import tweet from '../../images/tweet.mp4'

const DatasetsAsset = () => {
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      style={{
        width: '100%',
      }}
    >
      <source src={tweet} type="video/mp4" />
    </video>
  )
}

export default DatasetsAsset
