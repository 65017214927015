import React from 'react'
import { Grid, Typography } from '@material-ui/core'

type SpotlightItemProps = {
  icon: React.ReactNode
  title: string
  content: string
}

const SpotlightItem = ({ icon, title, content }: SpotlightItemProps) => {
  return (
    <Grid>
      {icon}
      <Typography
        variant="h6"
        style={{
          marginTop: '40px',
        }}
      >
        {title}
      </Typography>
      <Typography variant="body2">{content}</Typography>
    </Grid>
  )
}

export default SpotlightItem
