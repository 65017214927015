import { Grid, Typography } from '@material-ui/core'
import { ExternalNavLink } from '../navbar/navbar'

const title = 'Control Your Data Quality'
const content =
  "LightTag's Review Mode and Reporting make it easy to ensure " +
  ' your data is perfect and your annotators are performing at their very best. '

const EasyReviewVP = () => {
  return (
    <Grid>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">
        {content}
        <ExternalNavLink
          to={
            'https://www.lighttag.io/help/en/articles/4364945-reviewing-and-qa-basics'
          }
        >
          Learn More
        </ExternalNavLink>
      </Typography>
    </Grid>
  )
}

export default EasyReviewVP
