import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { demoUrl, freemiumSignupURL } from '../../utils/urls'
import Button from '@material-ui/core/Button'
import hero from '../../images/hero.mp4'
import { HrefButton } from '../common/HrefButton'

const title = 'The Text Annotation Tool For Teams'
const subtitle1 = 'Label data for NLP faster with your team and our AI.'
const subtitle2 =
  'LightTag manages your workforce so you can focus on the important things.'
const subtitle3 = 'Best of all, It. Just. Works.'
const tryDemo = 'Start For Free'
const seeVideo = 'See how it works'

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('md')]: {
      padding: '0 20px',
    },
  },

  subtitle: {
    margin: '40px auto 50px auto',
    [theme.breakpoints.down('md')]: {
      padding: '0 20px',
    },
  },

  callToActionButtons: {
    maxWidth: '450px',
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      justify: 'center',
    },
  },

  tryDemoButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

const Hero = () => {
  const classes = useStyles()
  return (
    <Box>
      <Grid
        container
        direction="column"
        alignContent="center"
        justify="center"
        style={{
          paddingTop: '50px',
        }}
      >
        <Typography
          component="h1"
          variant="h1"
          align="center"
          className={classes.title}
        >
          {title}
        </Typography>
        <Grid
          item
          direction="column"
          justify="center"
          className={classes.subtitle}
        >
          <Typography component="h2" variant="body1" align="justify">
            {subtitle1}
          </Typography>
          <Typography component="h2" variant="body1" align="justify">
            {subtitle2}
          </Typography>
          <Typography component="h2" variant="body1" align="justify">
            {subtitle3}
          </Typography>
        </Grid>
        <Box
          style={{
            maxWidth: '520px',
            margin: '0 auto 50px auto',
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              maxWidth: '520px',
            }}
          >
            <source src={hero} type="video/mp4" />
          </video>
        </Box>
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.callToActionButtons}
        >
          <Grid item xs={12} md={6}>
            {/*<Button*/}
            {/*  disableElevation*/}
            {/*  variant="contained"*/}
            {/*  color="secondary"*/}
            {/*  size="large"*/}
            {/*  href={'/pricing'}*/}
            {/*  className={classes.tryDemoButton}*/}
            {/*  disabled={true}*/}
            {/*  */}
            {/*>*/}
            {/*  Start For Free*/}
            {/*</Button>*/}
          </Grid>
          <Grid item xs={12} md={6}>
            {/*<HrefButton*/}
            {/*  disableElevation*/}
            {/*  variant={'outlined'}*/}
            {/*  color="secondary"*/}
            {/*  size="large"*/}
            {/*  href={'/contact'}*/}
            {/*>*/}
            {/*  Contact Us*/}
            {/*</HrefButton>*/}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Hero
