import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero/hero'
import TwoColumnSection, {
  TwoColumnSectionStyle,
} from '../components/two_column_section/two_column_section'
import WorkFasterAsset from '../components/assets/work_faster_asset'
import WorkFasterVP from '../components/sections/work_faster_vp'
import EasyReviewAsset from '../components/assets/easy_review_asset'
import EasyReviewVP from '../components/sections/easy_review_vp'
import DoubleThroughputAsset from '../components/assets/double_throughput_asset'
import DoubleThroughputVP from '../components/sections/double_throughput_vp'
import ModelAccuracyVP from '../components/sections/model_accuracy_vp'
import ModelAccuracyAsset from '../components/assets/model_accuracy_asset'
import DatasetsVP from '../components/sections/datasets_vp'
import DatasetsAsset from '../components/assets/datasets_asset'
import SpotlightItem from '../components/spotlight_item/spotlight_item'
import DataAsset from '../components/assets/data_asset'
import SearchAsset from '../components/assets/search_asset'
import ScaleAsset from '../components/assets/scale_asset'
import ControlAsset from '../components/assets/control_asset'
import UsersSection from '../components/users_section/users_section'
import PricingSection from '../components/pricing_section/pricing_section'
import TestimonialCarousel from '../components/testimonial_carousel/testimonial_carousel'

const dataTitle = 'Better Labeled Data, Faster'
const dataContent =
  'Assign tasks, particular datasets, assign how many annotators you want for each example.'

const scaleTitle = 'Cut Project Management Costs In Half'
const scaleContent =
  'Assign tasks, particular datasets, assign how many annotators you want for each example.'
const controlTitle = 'Give Your Data Scientists The Best Data'
const controlContent =
  "With LightTag's review, reporting and quality controls your team can be confident they have the best data."
const searchTitle = 'Do What You Do Best'
const searchContent =
  "Free your engineers from undifferentiated heavy lifting to focus on what's core to your business."

const IndexPage = () => (
  <Layout
    pageTitle="LightTag  - The Text Annotation Tool For Teams"
    pageDescription="The Text Annotation Tool for Teams With Active Learning and Search"
    ogImageUrl="http://static.lighttag.io/og.png"
  >
    <Hero />
    <TwoColumnSection
      leftCol={<WorkFasterVP />}
      rightCol={<WorkFasterAsset />}
      topPadding={220}
      alignItems="flex-start"
    />
    <TwoColumnSection
      leftCol={<EasyReviewAsset />}
      rightCol={<EasyReviewVP />}
      topPadding={160}
      bottomPadding={140}
    />
    <TwoColumnSection
      leftCol={<DoubleThroughputVP />}
      rightCol={<DoubleThroughputAsset />}
      style={TwoColumnSectionStyle.DARK}
      topPadding={150}
      bottomPadding={112}
    />
    <TwoColumnSection
      leftCol={<ModelAccuracyVP />}
      rightCol={<ModelAccuracyAsset />}
      style={TwoColumnSectionStyle.DARK}
      bottomPadding={150}
    />
    <TwoColumnSection
      leftCol={<DatasetsVP />}
      rightCol={<DatasetsAsset />}
      topPadding={160}
    />
    <TwoColumnSection
      leftCol={
        <SpotlightItem
          icon={<DataAsset />}
          title={dataTitle}
          content={dataContent}
        />
      }
      rightCol={
        <SpotlightItem
          icon={<ControlAsset />}
          title={controlTitle}
          content={controlContent}
        />
      }
      topPadding={180}
      alignItems="flex-start"
    />
    <TwoColumnSection
      leftCol={
        <SpotlightItem
          icon={<ScaleAsset />}
          title={scaleTitle}
          content={scaleContent}
        />
      }
      rightCol={
        <SpotlightItem
          icon={<SearchAsset />}
          title={searchTitle}
          content={searchContent}
        />
      }
      topPadding={100}
      bottomPadding={70}
      alignItems="flex-start"
    />
    <TestimonialCarousel />
    <UsersSection />
    <PricingSection />
  </Layout>
)

export default IndexPage
